// The dropdown menu
.dropdown-menu {
  z-index: $zindex-dropdown;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0;
  font-size: $font-size-base;
  color: $body-color;
  background-color: $dropdown-bg;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);

  &-small {
    @include box-shadow($dropdown-small-box-shadow);
    padding: $dropdown-small-padding-y 0;
    font-size: $dropdown-small-font-size;
    
    .dropdown-item {
      padding: $dropdown-small-item-padding-y $dropdown-small-item-padding-x;
      font-size: $dropdown-small-item-font-size;
    }

    .dropdown-divider {
      margin: $dropdown-small-divider-margin-y $dropdown-small-divider-margin-x;
    }
  }
}

.dropup .dropdown-menu {
  margin-bottom: $dropdown-spacer;
}

.dropright .dropdown-menu {
  margin-left: $dropdown-spacer;
}

.dropleft .dropdown-menu {
    margin-right: $dropdown-spacer;
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  @include nav-divider($dropdown-divider-bg);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  font-size: $dropdown-item-font-size;
  transition: $dropdown-item-transition;

  @include hover-focus {
    color: $dropdown-link-hover-color;
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    @include gradient-bg($dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    
    &:hover {
      background: none;
      cursor: not-allowed;
    }
  }
}

// Dropdown section headers
.dropdown-header {
  padding: $dropdown-padding-y $dropdown-item-padding-x;
  font-size: $font-size-sm;
  color: $dropdown-header-color;
}