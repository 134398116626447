// Shards Typography Adjustments

//
// Google Fonts
//

@if $enable-fonts-import {
  @import url($google-fonts-import);
}

//
// Headings
//

// Ensure elements that are not headings are properly
// displayed as block level elements.
.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
}

h1, .h1 {
  font-size: $h1-font-size;
  letter-spacing: $headings-letter-spacing;
  line-height: $h1-line-height;
}

h2, .h2 {
  font-size: $h2-font-size;
  letter-spacing: $headings-letter-spacing;
  line-height: $h2-line-height;
}

h3, .h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

h6, .h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

.lead {
  line-height: $lead-line-height;
}

// Type display classes
.display-1,
.display-2,
.display-3,
.display-4 {
  margin-bottom: $display-margin-bottom;
}

.display-1 {
  font-size: $display1-size;
  line-height: $display-line-height;
}

.display-2 {
  font-size: $display2-size;
  line-height: $display-line-height;
}

.display-3 {
  font-size: $display3-size;
  line-height: $display-line-height;
}

.display-4 {
  font-size: $display4-size;
  line-height: $display-line-height;
}

//
// Paragraphs
//

p {
  margin-bottom: $paragraphs-margin-bottom;
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border-top: $hr-border-width solid $hr-border-color;
}

//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}

//
// Misc
//

// Blockquotes
.blockquote {
  margin-bottom: $blockquote-margin-bottom;
  font-size: $blockquote-font-size;
}

.blockquote-footer {
  font-size: $blockquote-footer-font-size;
}
