//
// Base styles
//

.alert {
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: none;
  @include border-radius($alert-border-radius);
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
.alert-dismissible {
  .close {
    top: 0;
    right: 0;
    padding: $alert-padding-y $alert-padding-x;
    @include transition($transition-base);

    &:hover {
      cursor: pointer;
    }
  }
}


// Alternate styles
// Override alert theme colors without mixin.
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    color: lighten($value, 48%);
    background-color: $value;

    .alert-link {
      color: lighten($value, 48%);
    }

    // Exception for alerts with a light background.
    @if $color == "light" {
      color: color-yiq($value);

      .alert-link {
        color: color-yiq($value);
      }
    }
  }
}
