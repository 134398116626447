// Navbar adjustments

// Navbar
.navbar {
  padding: $navbar-padding-y $navbar-padding-x;
}

// Navbar brand
.navbar-brand {
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  margin-right: $navbar-padding-x;
  font-size: $navbar-brand-font-size;
  font-family: $navbar-font-family;
  font-weight: 400;
}

// Navbar text
.navbar-text {
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  font-size: $navbar-toggler-font-size;
  background: $white;
  border: $border-width solid transparent; // remove default button style
  @include border-radius($navbar-toggler-border-radius);
}

// Bootstrap already generates a series of `.navbar-expand-*` responsive classes
// for configuring where your navbar collapses. This just adjusts the nav link's
// left and right padding.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        .navbar-nav {
          .nav-link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }
      }
    }
  }
}

// Navbar themes

// Dark links against a light background
.navbar-light {
  .navbar-brand {
    color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
    background: none;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-brand {
    color: $navbar-dark-active-color;

    @include hover-focus {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
    background: none;
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-dark-color;
    a {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }
  }
}
