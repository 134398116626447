// Tooltips adjustments

// Base class
.tooltip {
  z-index: $zindex-tooltip;
  margin: $tooltip-margin;  
  @include reset-text();
  font-size: $tooltip-font-size;

  &.show { opacity: $tooltip-opacity; }

  .arrow {
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow::before {
    border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
    border-top-color: $tooltip-arrow-color;
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow::before {
    border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
    border-bottom-color: $tooltip-arrow-color;
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  background-color: $tooltip-bg;
  box-shadow: $tooltip-box-shadow;
  @include border-radius($border-radius);
}