// Modal adjustments

.modal {
  z-index: $zindex-modal;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  margin: $modal-dialog-margin;
  
  // When fading in the modal, animate it to slide down
  .modal.fade & {
    @include transition($modal-transition);
  }
}

.modal-dialog-centered {
  min-height: calc(100% - (#{$modal-dialog-margin} * 2));
}

// Actual modal
.modal-content {
  background-color: $modal-content-bg;
  border: none;
  @include border-radius($border-radius-lg);
  @include box-shadow($modal-content-box-shadow);
}

// Modal background
.modal-backdrop {
  z-index: $zindex-modal-backdrop;
  background-color: $modal-backdrop-bg;

  &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
}

// Title text within header
.modal-title {
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  padding: $modal-footer-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow);
  }

  .modal-sm { max-width: $modal-sm; }

}

@include media-breakpoint-up(lg) {
  .modal-lg { max-width: $modal-lg; }
}